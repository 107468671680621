import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    constructor(private platform: Platform) { }

    public isBrowserSupported(): boolean {
        return this.platform.WEBKIT || this.platform.BLINK;
    }

    public warned(): void {
        sessionStorage['platformWarning'] = true;
    }

    public hasAlreadyBeenWarned(): boolean {
        return !!sessionStorage['platformWarning'];
    }
}
